import React, {Fragment} from 'react';
import './App.css';
import { BrowserRouter as HashRouter, Routes } from "react-router-dom"
import { Router } from './routers/index'
import history from './utils/history'
import routes from './routers/index'
class App extends React.Component {
  render() {
    return (
      <HashRouter >
        <Router history={history} />
      </HashRouter>
    )
  }
}
export default App;

