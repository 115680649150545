import {useRoutes, useNavigate } from "react-router-dom";
import { Suspense, lazy,  Space, Spin } from 'react'
import { LoadingOutlined } from '@ant-design/icons';

export const routes = [
  {
    path: '/',
    auth:false,
    component:lazy(() => import('../pages/Login'))
  },
  {
    path: '/home',
    auth:true,
    component:lazy(() => import('../pages/home')),
    children: [
      {
        path: '/home/product',
        auth:true,
        component:lazy(() => import('../compontens/Product/index'))
      },
      {
        path: '/home/newProduct',
        auth:true,
        component:lazy(() => import('../compontens/Product/NewProduct/index.jsx'))
      },
      {
        path: '/home/productDevelopment',
        auth:true,
        component:lazy(() => import('../compontens/Product/ProductDevelopment/index'))
      },
      {
        path: '/home/ProductConsumables',
        auth:true,
        component:lazy(() => import('../compontens/Product/ProductConsumables/index'))
      },
      {
        path: '/home/MessagePush',
        auth:true,
        component:lazy(() => import('../compontens/Product/MessagePush/index'))
      },
      {
        path: '/home/DistributionNetwork',
        auth:true,
        component:lazy(() => import('../compontens/Product/DistributionNetwork/index'))
      },
      {
        path: '/home/VoicePacketManagement',
        auth:true,
        component:lazy(() => import('../compontens/Product/VoicePacketManagement/index'))
      },
      {
        path: '/home/Multilingual',
        auth:true,
        component:lazy(() => import('../compontens/Product/Multilingual/index'))
      },
      {
        path: '/home/KnowledgeBase',
        auth:true,
        component:lazy(() => import('../compontens/Product/KnowledgeBase/index'))
      },
      {
        path: '/home/ProductManual',
        auth:true,
        component:lazy(() => import('../compontens/Product/ProductManual/index'))
      },
      {
        path: '/home/FirmwareOTA',
        auth:true,
        component:lazy(() => import('../compontens/Product/FirmwareOTA/index'))
      },
      {
        path: '/home/DeviceManagement',
        auth:true,
        component:lazy(() => import('../compontens/Product/DeviceManagement/index'))
      },
      {
        path: '/home/ProductDetails',
        auth:true,
        component:lazy(() => import('../compontens/ProductDetails/index'))
      },
      {
        path: '/home/ProductKnowledgeCreation',
        auth:true,
        component:lazy(() => import('../compontens/ProductKnowledgeCreation/index'))
      },
      {
        path: '/home/KnowledgeCreation',
        auth:true,
        component:lazy(() => import('../compontens/KnowledgeCreation/index'))
      },
      {
        path: '/home/VoicepacketMarket',
        auth:true,
        component:lazy(() => import('../compontens/VoicepacketMarket/index'))
      },
      {
        path: '/home/ServiceDetails',
        auth:true,
        component:lazy(() => import('../compontens/ServiceDetails/index'))
      },
      {
        path: '/home/ActivateNow',
        auth:true,
        component:lazy(() => import('../compontens/ActivateNow/index'))
      },
      {
        path: '/home/ShelfServiceAll',
        auth:true,
        component:lazy(() => import('../compontens/ShelfServiceAll/index'))
      },
      {
        path: '/home/EditProductKnowledge',
        auth:true,
        component:lazy(() => import('../compontens/Product/EditProductKnowledge/index'))
      },
      {
        path: '/home/ProductKnowledge',
        auth:true,
        component:lazy(() => import('../compontens/Product/ProductKnowledge/index'))
      }, {
        path: '/home/PIDKnowledge',
        auth:true,
        component:lazy(() => import('../compontens/Product/PIDKnowledge/index'))
      }, {
        path: '/home/AccountAuthentication',
        auth:true,
        component:lazy(() => import('../compontens/AccountAuthentication/index'))
      },
      {
        path: '/home/app/index',
        auth:true,
        component:lazy(() => import('../compontens/APP/index'))
      },
      {
        path: '/home/app/appDevelopment',
        auth:true,
        component:lazy(() => import('../compontens/APP/AppDevelopment/index'))
      },
      {
        path: '/home/app/shelfConfiguration',
        auth:true,
        component: lazy(() => import('../compontens/APP/ShelfConfiguration/index')),
        children: [
          {
            path: '/home/app/shelfConfiguration/yinsi',
            auth:true,
            component: lazy(() => import('../compontens/APP/ShelfConfiguration/index')),
          }
        ]
      },
      {
        path: '/home/app/optionalConfiguration',
        auth:true,
        component:lazy(() => import('../compontens/APP/OptionalConfiguration/index'))
      },
      {
        path: '/home/app/developmentTool',
        auth:true,
        component:lazy(() => import('../compontens/APP/DevelopmentTool/index'))
      },
      {
        path: '/home/developmentTool',
        auth:true,
        component:lazy(() => import('../compontens/Product/DevelopmentTool/index'))
      },
      {
        path: '/home/ScreenConfiguration',
        auth:true,
        component:lazy(() => import('../compontens/Product/ScreenConfiguration/index'))
      },
      {
        path: '/home/multilingualManagement',
        auth:true,
        component:lazy(() => import('../compontens/APP/MultilingualManagement/index'))
      },
      {
        path: '/home/discover/DiscoveryPlate',
        auth: true,
        component: lazy(() => import('../compontens/Operate/DiscoveryPlate/index')),
      },
    ]
  },
  {
    path: '/EditQuestionnaire',
    auth:false,
    component:lazy(() => import('../compontens/Operate/EditQuestionnaire/index'))
  },
  // {
  //   path: '/app',
  //   auth:true,
  //   component:lazy(() => import('../pages/homeAPP')),
  //   children: [
  //     {
  //       path: '/app/index',
  //       auth:true,
  //       component:lazy(() => import('../compontens/APP/index'))
  //     },
  //     {
  //       path: '/app/appDevelopment',
  //       auth:true,
  //       component:lazy(() => import('../compontens/APP/AppDevelopment/index'))
  //     },
  //   ]
  // },
  {
    path: '/DocumentCenter',
    auth:false,
    component:lazy(() => import('../pages/DocumentCenter'))
  },
  {
    path: '/DetailedConfiguration',
    auth:false,
    component:lazy(() => import('../compontens/APP/DetailedConfiguration/index'))
  },
  {
    path: '/TechnicalSupport',
    auth:false,
    component:lazy(() => import('../compontens/TechnicalSupport'))
  },
  {
    path: '/NewWorkOrder',
    auth:false,
    component:lazy(() => import('../compontens/TechnicalSupport/NewWorkOrder'))
  },
  {
    path: '/WorkOrderDetails',
    auth:false,
    component:lazy(() => import('../compontens/TechnicalSupport/WorkOrderDetails'))
  },
  
  // {
  //   path: '*',
  //   auth:false,
  //   component:lazy(() => import('../page/error/NotFound'))
  // }
]


/**
 * 根据路径获取路由
 * */
const checkAuth = (routers, path)=>{
  for (const data of routers) {
    if (data.path===path) return data
    if (data.children) {
      const res = checkAuth(data.children, path)
      if (res) return res
    }
  }
  return null
}
 
/**
 * 路由处理方式
 * */ 
const generateRouter = (routers) => {
  
    return routers.map((item) => {
      if (item.children) {
        item.children = generateRouter(item.children)
      }
      item.element = <Suspense fallback={
        <div direction="vertical"
          style={{ width: '100%', height: '100vh', lineHeight:'30%', textAlign: 'center', color: '#024CFF' }}>
         <LoadingOutlined></LoadingOutlined>  加载中...
          </div>
      }>
        {/* 把懒加载的异步路由变成组件装载进去 */}
        <item.component />
      </Suspense>
      return item
    })
 
 
}
 
const Router = () => useRoutes(generateRouter(routes))
const checkRouterAuth = (path) => {
  let auth = null
  auth = checkAuth(routes,path)
  return auth
}
export{ Router,checkRouterAuth }